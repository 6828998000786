html, body { width: 100%; height: 100%; }

/*body {
    background-color: #000;
    background-image: url('http://i.imgur.com/YwODqUc.png');
    background-repeat: no-repeat;
    background-position: center center;
}*/

#drop_zone {
    border: 5px solid blue;
    width:  800px;
    height: 500px;
  }
